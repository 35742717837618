//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
//-----------------------------------------------------------------------------------------------------------------

export function initSideMenu() {
	$('.hamburger').click( function() {

		if ( !$('html').hasClass('show-sideMenu')) {
			$('html, body').scrollTop(0);
			$('#sideMenuContainer').scrollTop(0);
			$('#sideMenuContainer > div').scrollTop(0);
			$('html').addClass('show-sideMenu');
			$('html').addClass('noscroll');
		}
		else {
			$('html').removeClass('show-sideMenu');
			$('html').removeClass('noscroll');
			//initCloseSubMenu();
		}
		return false;
	});

	$('#sideMenu .sub > a').click( function() {
		var clickedli = $(this).parent();
		var clickedul = $(this).parent().parent();
		var toggling = clickedli.hasClass('open');

		$('li.open div', clickedul).animate( {height: 'hide'}, {duration: 400, specialEasing: { height: 'easeOutExpo' },
				complete: function() {
				  $(this).css('height', 'auto');
				}});
		$('li.open', clickedul).removeClass('open');

		if ( !toggling ) {
			setTimeout(function() { clickedli.addClass('open'); }, 0);
			$('> div', clickedli).stop(true, false).animate( {height: 'show'}, { duration: 400, specialEasing: { height: 'easeOutExpo' },
				complete: function() {
				  $(this).css('height', 'auto');
				}});
		}

		return false;
	});
}

function initCloseSubMenu() {
	setTimeout(function() {
		$('#mainLinks li.open div').hide();
		$('#mainLinks li.open').removeClass('open');
		$('#mainLinks').removeClass('onDuty');
	}, 650);
}

// Fonction gérant le drop down du header
export function dropDownHeader() {
	var delay = 0
	var setTimeoutConst

	$('#mainNav .dropDownBtn').hover(function () {
	  setTimeoutConst = setTimeout(function () {
		delay = 300
		$('html').addClass('showOverlayDropDown')
		addNoScroll()
	  }, delay)
	}, function () {
	  clearTimeout(setTimeoutConst)
	})

	$('.overlay, #mainNav a:not(.dropDownBtn), header #logo').on('mouseover', function() {
		if($('html').hasClass('showOverlayDropDown')) {
			delay = 0

			$('html').addClass('closingOverlay')
			setTimeout(function () {
				$('html').removeClass('showOverlayDropDown').removeClass('closingOverlay')
				removeNoScroll()
			}, 700)
		}
	})

	$('#overlay-dropDown a').on('click', function() {
	  delay = 0
	  $('html').addClass('closingOverlay')
		setTimeout(function () {
			$('html').removeClass('showOverlayDropDown').removeClass('closingOverlay')
			removeNoScroll()
		}, 700)
	})
}

function addNoScroll() {
	$(document).scrollTop(0)
	if($(document).height() > $(window).height()) {
		var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
		$('html').addClass('noscroll').css('top',-scrollTop)
	}
}

function removeNoScroll() {
	$('html').removeClass('noscroll')
	$('html,body').scrollTop(0)
}

export function overlayShare() {
	const overlayShare = new Overlay('overlayShare', '.btnShare', 'showOverlayShare', '.closeOverlay', true, false)
  }
