//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Innovation, Cycle, Contact, Distributeur, DevenirDistributeur, Dimension, PolitiqueDeGarantie, PolitiqueDeConfidentialite,
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'stdView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init();


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init();

  // Page innovation __________
  Barba.BaseView.extend({
    namespace: 'innovationView',
    onEnter:          function() {},
    onEnterCompleted: function() { Innovation.initInnovation()  },
    onLeave:          function() { Innovation.leaveInnovation() },
    onLeaveCompleted: function() {}
  }).init();

  // Page avec cycle __________
  Barba.BaseView.extend({
    namespace: 'cycleView',
    onEnter:          function() {},
    onEnterCompleted: function() { Cycle.initCycle()  },
    onLeave:          function() { Cycle.leaveCycle() },
    onLeaveCompleted: function() {}
  }).init();

  // Page nous joindre __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init();

  // Page devenir distributeur __________
  Barba.BaseView.extend({
    namespace: 'devenirDistributeurView',
    onEnter:          function() {},
    onEnterCompleted: function() { DevenirDistributeur.initDevenirDistributeur()  },
    onLeave:          function() { DevenirDistributeur.leaveDevenirDistributeur() },
    onLeaveCompleted: function() {}
  }).init();

  // Page trouver distributeur __________
  Barba.BaseView.extend({
    namespace: 'distributeurView',
    onEnter:          function() {},
    onEnterCompleted: function() { Distributeur.initDistributeur()  },
    onLeave:          function() { Distributeur.leaveDistributeur() },
    onLeaveCompleted: function() {}
  }).init();

  // Page calcul dimension __________
  Barba.BaseView.extend({
    namespace: 'dimensionView',
    onEnter:          function() {},
    onEnterCompleted: function() { Dimension.initDimension()  },
    onLeave:          function() { Dimension.leaveDimension() },
    onLeaveCompleted: function() {}
  }).init();

  // Page politique de garantie __________
  Barba.BaseView.extend({
    namespace: 'politiqueDeGarantieView',
    onEnter:          function() {},
    onEnterCompleted: function() { PolitiqueDeGarantie.initPolitiqueDeGarantie()  },
    onLeave:          function() { PolitiqueDeGarantie.leavePolitiqueDeGarantie() },
    onLeaveCompleted: function() {}
  }).init();

  // Page politique de garantie __________
  Barba.BaseView.extend({
    namespace: 'politiqueDeConfidentialiteView',
    onEnter:          function() {},
    onEnterCompleted: function() { PolitiqueDeConfidentialite.initPolitiqueDeConfidentialite()  },
    onLeave:          function() { PolitiqueDeConfidentialite.leavePolitiqueDeConfidentialite() },
    onLeaveCompleted: function() {}
  }).init();
}
