//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


// Transforme les selects
export function magicSelect() {
  $(".chosen-select").chosen({ disable_search: true })
  $(".chosen-select").val(window.location.pathname.split("/").pop()).trigger('chosen:updated');
}

// Validation du formulaire de l'infolettre
export function infolettreForm(form, submit) {
	var m = getMessages();
	var rules = 	{ courriel: { required: true, email: true }};
	var messages = 	{ courriel: { required: m.infolettre, email: m.email }};
	formValidation(form, submit, rules, messages);
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
	    infolettre: 'Veuillez fournir votre adresse électronique.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Validation du formulaire page contact
export function contactForm(form, submit) {
	var m = getMessages();

	var rules = 	{
    produit: { required: true },
    dimensions: { required: true },
		nom: 		{ required: true },
		prenom: 	{ required: true },
		courriel: 	{ required: true, email: true },	
		message: 	{ required: true }
	};

	var messages = 	{
    produit: { required: m.required },
    dimensions: { required: m.required },
		nom: 		{ required: m.required },
		prenom: 	{ required: m.required },
		courriel: 	{ required: m.required, email: m.email },		
		message: 	{ required: m.required }
	};

	formValidation(form, submit, rules, messages);
}

// Validation du formulaire devenir distributeur
export function distributeurForm(form, submit) {
  formatPhone('#telephone');
	var m = getMessages();

	var rules = 	{
    nom: { required: true },
    prenom: { required: true },
		entreprise: 		{ required: true },
		telephone: 	{ required: true, phone: true },
		courriel: 	{ required: true, email: true },	
		message: 	{ required: true }
	};

	var messages = 	{
    nom: { required: m.required },
    prenom: { required: m.required },
		entreprise: 		{ required: m.required },
		telephone: 	{ required: m.required, phone: m.phone },
		courriel: 	{ required: m.required, email: m.email },		
		message: 	{ required: m.required }
	};

	formValidation(form, submit, rules, messages);
}

// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({ ignore: [] })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function(e) {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
	  return false;
  })
}