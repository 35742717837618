//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

export function qualityCycle() {
	$('#qualityCycle').slick({
		zindex: 1,
		arrows: false,
		dots: true,
		appendDots: '#qualityCycleDots',
		customPaging: function(slider, i) {
            return $('<button type="button" />').text('0' + (i + 1));
        },
		easing: 'easeInOutExpo',
		autoplay: false,
		draggable: true,
		pauseOnHover: false,
		fade: true,
		autoplaySpeed: 7000,
		speed: 500
	});
}

export function picCycle() {
	$('#picCycle').slick({
		zindex: 1,
		arrows: false,
		dots: true,
		appendDots: '#picCycleLayer',
		easing: 'easeInOutExpo',
		autoplay: false,
		draggable: true,
		pauseOnHover: false,
		fade: true,
		autoplaySpeed: 7000,
		speed: 500
	});
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}