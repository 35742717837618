//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { productBloc, tiroirs, addVideo, shareModal, fixedNav, scrollToBlock } from './functions/functions.js'
import { noSamePageDestination, removeHoverOnMobile } from './functions/helper.js'
import { initSideMenu, dropDownHeader, overlayShare } from './functions/overlay.js'
import { infolettreForm, contactForm, distributeurForm, magicSelect } from './functions/forms.js'
import { qualityCycle, picCycle, destroySlick } from './functions/slicks.js'
import { Parallax } from './classes/Parallax.js'
import { Scrollfire } from './classes/Scrollfire.js'
//-----------------------------------------------------------------------------------------------------------------

// Initialisation sur toutes les pages
export class View {
  static init() {
    fixedNav()
    overlayShare()
    noSamePageDestination()
    removeHoverOnMobile()
    shareModal()
    initSideMenu()
    dropDownHeader()
    scrollToBlock('#scrollUp', '')
    window.parallax = new Parallax()
    window.scrollfire = new Scrollfire()
    infolettreForm('#infolettreForm', '#infolettreFormSubmit')
  }

  static leave() {}
}

// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    qualityCycle()
    productBloc()
    addVideo()
  }

  static leaveHome() {
    this.leave()
	  destroySlick('#qualityCycle')
  }
}

// Classe initialisant les scripts présents dans la page innovation
export class Innovation extends View {
  static initInnovation() {
    this.init()
	  qualityCycle()
    picCycle()
  }

  static leaveInnovation() {
    this.leave()
	  destroySlick('#qualityCycle')
	  destroySlick('#picCycle')
  }
}

// Classe initialisant les scripts présents dans les pages avec cycle (produits)
export class Cycle extends View {
  static initCycle() {
    this.init()
    picCycle()
    tiroirs()
    scrollToBlock('#scrollOptions', '#options')
  }

  static leaveCycle() {
    this.leave()
	  destroySlick('#picCycle')
  }
}

// Classe initialisant les scripts présents dans la page contact
export class Contact extends View {
  static initContact() {
    this.init()
    contactForm('#contactForm', '#contactFormSubmit')
    magicSelect()
  }

  static leaveContact() {
    this.leave()
  }
}

export class DevenirDistributeur extends View {
  static initDevenirDistributeur() {
    this.init()
    distributeurForm('#distributeurForm', '#distributeurFormSubmit')
  }

  static leaveDevenirDistributeur() {
    this.leave()
  }
}

export class Distributeur extends View {
  static initDistributeur() {
    this.init()
    tiroirs()
  }

  static leaveDistributeur() {
    this.leave()
  }
}

export class Dimension extends View {
  static initDimension() {
    this.init()
  }

  static leaveDimension() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page politique de garantie
export class PolitiqueDeGarantie extends View {
  static initPolitiqueDeGarantie() {
    this.init()
  }

  static leavePolitiqueDeGarantie() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page politique de confidentialité
export class PolitiqueDeConfidentialite extends View {
  static initPolitiqueDeConfidentialite() {
    this.init()
  }

  static leavePolitiqueDeConfidentialite() {
    this.leave()
  }
}
